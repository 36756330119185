import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';

// Heading scrub animation
export function headingScrub(scrollRef, scrubDistance) {
  if (document.querySelector('.hero-marquee')) {
    gsap.from('.hero-marquee', {
      scrollTrigger: {
        trigger: '.hero-marquee',
        scrub: true,
        start: 'top bottom',
        end: 'bottom top',
        scroller: scrollRef.current,
      },
      x: scrubDistance,
      ease: 'none',
    });
  }
}

// Trigger animations on scroll

// Elements reveal: text
export function splitReveal(scrollRef, triggerPoint) {
  if (document.querySelector('.split-lines')) {
    const scrollText = document.querySelectorAll('.split-lines');

    scrollText.forEach((singleText) => {
      const splittedTexts = new SplitType(singleText, { types: 'lines' });

      gsap.from(splittedTexts.lines, {
        duration: 0.8,
        y: 40,
        opacity: 0,
        stagger: 0.08,
        ease: 'power2.out',

        scrollTrigger: {
          trigger: singleText,
          start: triggerPoint,
          toggleActions: 'play none none none',
          once: true,
          scroller: scrollRef.current,
        },
      });
    });
  }
}

// Elements reveal: elements (buttons, links, texts)
export function elementReveal(scrollRef, triggerPoint) {
  if (document.querySelector('.element-reveal')) {
    const revealElements = document.querySelectorAll('.element-reveal');

    revealElements.forEach((element) => {
      gsap.from(element, {
        duration: 0.8,
        y: 40,
        opacity: 0,
        ease: 'power2.out',

        scrollTrigger: {
          trigger: element,
          start: triggerPoint,
          toggleActions: 'play none none none',
          once: true,
          scroller: scrollRef.current,
        },
      });
    });
  }
}

// Elements reveal: Big headings
export function buildReveal(scrollRef, triggerPoint) {
  if (document.querySelector('.build-reveal')) {
    const buildTexts = document.querySelectorAll('.build-reveal');

    buildTexts.forEach((text) => {
      gsap.fromTo(
        text,
        {
          y: '100%',
          rotationX: -90,
          opacity: 0,
        },
        {
          duration: 0.8,
          y: '0%',
          rotationX: 0,
          opacity: 1,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: text,
            start: triggerPoint,
            toggleActions: 'play none none none',
            once: true,
            scroller: scrollRef.current,
          },
        }
      );
    });
  }
}

// Elements reveal: stagger
export function staggerReveal(scrollRef, triggerPoint) {
  if (document.querySelector('.stagger-reveal')) {
    const staggerItems = document.querySelectorAll('.stagger-reveal');

    staggerItems.forEach((staggerParent) => {
      const allChild = staggerParent.children;

      gsap.from(allChild, {
        duration: 0.8,
        y: 60,
        opacity: 0,
        ease: 'power2.out',
        stagger: 0.1,

        scrollTrigger: {
          trigger: staggerParent,
          start: triggerPoint,
          toggleActions: 'play none none none',
          once: true,
          scroller: scrollRef.current,
        },
      });
    });
  }
}

// Mask reveal
export function maskReveal(scrollRef, triggerPoint) {
  if (document.querySelector('.mask-reveal')) {
    const revealMasks = document.querySelectorAll('.mask-reveal');

    revealMasks.forEach((element) => {
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: triggerPoint,
          toggleActions: 'play none none none',
          once: true,
          scroller: scrollRef.current,
        },
      });
      tl2.fromTo(
        element.querySelector('.mask'),
        { height: '100%' },
        {
          duration: 1,
          height: 0,
          ease: 'power2.out',
        }
      );
      tl2.from(
        element.querySelector('.image-gatsby'),
        {
          duration: 1,
          scale: 1.2,
          ease: 'power2.out',
        },
        '-=1'
      );
    });
  }
}

// Navbar animation
export function navbarChanger(scrollRef) {
  const navbar = document.querySelector('.navbar');
  if (navbar.classList.contains('invert')) {
    navbar.classList.remove('invert');
  }

  if (document.querySelector('.inverse-colors')) {
    ScrollTrigger.create({
      trigger: '.inverse-colors',
      start: 'top top',
      end: 'bottom top',
      scroller: scrollRef.current,
      // markers: true,
      onToggle: (self) => {
        if (self.isActive) {
          navbar.classList.add('invert');
        } else navbar.classList.remove('invert');
      },
    });
  }
}

// Onscoll service animation
export function serviceScrolled(scrollRef) {
  if (document.querySelector('#services .all-services')) {
    const scrollText = document.querySelectorAll('.all-services h3 .inner');

    const tls = gsap.timeline({
      scrollTrigger: {
        trigger: '.all-services',
        start: 'top 50%',
        toggleActions: 'play none none none',
        once: true,
        scroller: scrollRef.current,
      },
    });

    tls.fromTo(
      '.all-services h3',
      { opacity: 0 },
      {
        duration: 0.1,
        opacity: 1,
      },
      '-=0.1'
    );
    tls.fromTo(
      scrollText,
      {
        y: '100%',
        rotationX: -90,
        opacity: 0,
      },
      {
        duration: 0.8,
        y: '0%',
        rotationX: 0,
        opacity: 1,
        ease: 'power2.out',
        stagger: 0.2,
      },
      '-=0.1'
    );
    tls.fromTo(
      '.all-services p',
      {
        y: 40,
        opacity: 0,
      },
      {
        duration: 0.8,
        y: 0,
        opacity: 1,
        ease: 'power2.out',
      },
      '-=0.8'
    );

    tls.fromTo(
      '.service-pics .mask',
      { height: '100%' },
      {
        duration: 1,
        height: 0,
        ease: 'power2.out',
      },
      '-=1'
    );
    tls.from(
      '.service-pics .pics > div',
      {
        duration: 1,
        scale: 1.2,
        ease: 'power2.out',
      },
      '-=1'
    );
  }
}
