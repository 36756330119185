import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';

import { onLoadAnims } from '../utils/onloadAnims';
import {
  navbarChanger,
  headingScrub,
  splitReveal,
  elementReveal,
  buildReveal,
  staggerReveal,
  maskReveal,
  serviceScrolled,
} from '../utils/scrollAnims';

import '../css/main.scss';
import Cursor from './Cursor';
import Loading from './Loading';
import Footer from './Footer';
import Navbar from './Navbar';

gsap.registerPlugin(ScrollTrigger);

export default function Layout({
  children,
  location,
  loadingTexts,
  onLoadAnimations = [false, false],
  contactBtn = false,
  footer2 = false,
}) {
  const scrollRef = useRef(null);
  const loadingTl = gsap.timeline();

  useEffect(() => {
    const locoScroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
    });
    locoScroll.on('scroll', ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(scrollRef.current, {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: scrollRef.current.style.transform ? 'transform' : 'fixed',
    });

    // Onload animations
    onLoadAnims(loadingTl, onLoadAnimations[0], onLoadAnimations[1]);

    // Navbar animation
    navbarChanger(scrollRef);

    // Viewports
    const tabViewport = window.matchMedia('(max-width: 800px)');
    const phoneViewport = window.matchMedia('(max-width: 600px)');

    let scrubDistance;
    if (phoneViewport.matches) {
      scrubDistance = '90vw';
    } else if (tabViewport.matches) {
      scrubDistance = '65vw';
    } else scrubDistance = '40vw';

    let triggerPoint;
    if (tabViewport.matches) {
      triggerPoint = 'top 82%';
    } else triggerPoint = 'top 75%';

    // Reveal animations
    headingScrub(scrollRef, scrubDistance);
    splitReveal(scrollRef, triggerPoint);
    elementReveal(scrollRef, triggerPoint);
    buildReveal(scrollRef, triggerPoint);
    staggerReveal(scrollRef, triggerPoint);
    maskReveal(scrollRef, triggerPoint);

    // Onscoll service animation
    serviceScrolled(scrollRef);

    ScrollTrigger.addEventListener('refresh', () => locoScroll.update());
    ScrollTrigger.refresh();

    return () => {
      if (locoScroll) locoScroll.destroy();
    };
  }, [location, loadingTl, onLoadAnimations]);

  return (
    <>
      <Cursor location={location} />
      <Loading loadingTexts={loadingTexts} timeline={loadingTl} />

      <div ref={scrollRef} data-scroll-container className="scroll-container">
        {children}
        <Footer contactFooter={footer2} />
      </div>

      {/* to load after everything else */}
      <Navbar noNavBtn={contactBtn} />
    </>
  );
}
