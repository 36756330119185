import React from 'react';
// import { Link } from 'gatsby';

import UnbuiltWhite from '../images/unbuilt-white.svg';
import UnbuiltBlack from '../images/unbuilt-black.svg';

export default function Navbar({ noNavBtn }) {
  return (
    <header>
      <nav className="navbar">
        <a href="/" className="logo pointer-cursor">
          <img src={UnbuiltWhite} alt="Unbuilt logo" className="white-logo" />
          <img src={UnbuiltBlack} alt="Unbuilt logo" className="black-logo" />
        </a>

        {/* {!noNavBtn && (
          <Link to="/contact" className="button small">
            Get in touch
          </Link>
        )} */}

        {!noNavBtn && (
          <a href="/contact" className="button small">
            Get in touch
          </a>
        )}
      </nav>
    </header>
  );
}
