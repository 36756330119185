import React, { useEffect } from 'react';

import UnbuiltLogo from '../images/unbuilt-loader.svg';
import UnbuiltStroke from '../images/unbuilt-stroke.svg';

export default function Loading({ loadingTexts = false, timeline }) {
  useEffect(() => {
    function resetLoader() {
      const loadingScreen = document.querySelector('.loading');
      loadingScreen.style.opacity = '1';
      loadingScreen.style.visibility = 'visible';
    }
    resetLoader();

    if (loadingTexts) {
      const loadingTextStacks = document.querySelector(
        '.loading .loading-texts .all-stacks'
      );
      const allStacks = loadingTextStacks.querySelectorAll('.stack');
      const allImgs = [];
      allStacks.forEach((stack) => {
        allImgs.push(stack.querySelectorAll('img'));
      });

      timeline.fromTo(
        loadingTextStacks,
        {
          opacity: 0,
          scale: 0.2,
        },
        {
          duration: 0.6,
          opacity: 1,
          scale: 0.5,
          ease: 'power4.inOut',
        }
      );
      timeline.to(allImgs, {
        duration: 0.6,
        x: 0,
        ease: 'power4.inOut',
      });
      timeline.to(
        loadingTextStacks,
        {
          duration: 0.6,
          scale: 0.75,
          ease: 'power4.inOut',
        },
        '-=0.6'
      );
      timeline.to(allStacks, {
        duration: 0.6,
        y: 0,
        ease: 'power4.inOut',
      });
      timeline.to(
        loadingTextStacks,
        {
          duration: 0.6,
          scale: 1,
          // rotate: -10,
          ease: 'power4.inOut',
        },
        '-=0.6'
      );
    }

    // common to all: fades loading screen
    timeline.to(
      '.loading',
      {
        duration: 0.6,
        opacity: 0,
        ease: 'power2.out',
      },
      '+=0.3'
    );
    timeline.to('.loading', {
      duration: 0,
      visibility: 'hidden',
    });

    // reset opacity of texts to prevent flicker onLoad
    if (loadingTexts) {
      const loadingTextStacks = document.querySelector(
        '.loading .loading-texts .all-stacks'
      );
      timeline.to(loadingTextStacks, {
        duration: 0,
        opacity: 0,
      });
    }
  });

  return (
    <>
      <div className="loading">
        <div className="loading-icon">
          <img src={UnbuiltLogo} alt="unbuilt" />
          <div className="dot">
            <div className="dot-inner" />
          </div>
        </div>

        {loadingTexts && (
          <div className="loading-texts">
            <div className="all-stacks">
              <div className="stack stack-1">
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
              </div>
              <div className="stack stack-2">
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
              </div>
              <div className="stack stack-3">
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
              </div>
              <div className="stack stack-4">
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
              </div>
              <div className="stack stack-5">
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
                <img src={UnbuiltStroke} alt="UNBUILT" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
