import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';

const query = graphql`
  {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
        image
      }
    }
  }
`;

export default function SEO({ location, title, description, custom }) {
  const { site } = useStaticQuery(query);
  const { siteTitle, siteDesc, image } = site.siteMetadata;

  return (
    <Helmet title={custom ? `${title} | ${custom}` : `${siteTitle} | ${title}`}>
      <html lang="en" />
      <script src={withPrefix('/script.js')} type="text/javascript" />
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image} />
      {/* <link rel="canonical" href={location.href} /> */}
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link rel="alternate icon" href="/favicon.png" type="image/x-icon" />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || '/favicon.svg'} />
      <meta property="og:title" content={siteTitle} key="ogtitle" />
      <meta property="og:site_name" content={title} key="ogsitename" />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
      <meta name="msapplication-TileColor" content="#091922" />
      <meta name="theme-color" content="#091922" />
    </Helmet>
  );
}
