import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

// import UnbuiltWhite from '../images/unbuilt-white.svg';
import DribbbleIcon from '../images/ico-dribbble.svg';
import InstagramIcon from '../images/ico-instagram.svg';

export default function Footer({ contactFooter }) {
  const data = useStaticQuery(graphql`
    {
      sanityHomepage {
        footerText
      }
      sanitySiteSettings {
        email
        socials {
          dribbble
          instagram
          linkedIn
        }
      }
    }
  `);
  const { footerText } = data.sanityHomepage;
  const { email } = data.sanitySiteSettings;
  const { dribbble } = data.sanitySiteSettings.socials;
  const { instagram } = data.sanitySiteSettings.socials;

  return (
    <>
      <footer className="stuck-top" data-scroll-section>
        <div className="wrapper">
          {contactFooter ? (
            <div className="footer-text">
              <h2>
                <div className="main-text questions">
                  <div className="inner build-reveal">Have any questions?</div>
                </div>
              </h2>
              <p className="reach element-reveal">Reach us at</p>
              <a href={`mailto:${email}`} className="link element-reveal">
                {email}
              </a>
            </div>
          ) : (
            <div className="footer-text">
              <h2>
                <div className="main-text ready">
                  <div className="inner build-reveal">Ready to build the</div>
                </div>
                <div className="main-text unbuilt">
                  <div className="inner build-reveal">Unbuilt?</div>
                </div>
              </h2>
              <p className="split-lines">{footerText}</p>
              <a href="/contact" className="button element-reveal">
                Let's work together
              </a>
            </div>
          )}

          <div className="bottom">
            <div className="certificates">
              <div className="img">
                <StaticImage
                  src="../images/awwwards-honors.jpg"
                  alt="awwwards certificate"
                  quality={100}
                />
              </div>
              <div className="img">
                <StaticImage
                  src="../images/awwwards-mobile.jpg"
                  alt="awwwards certificate"
                  quality={100}
                />
              </div>
            </div>

            <div className="social">
              <a
                href={dribbble}
                target="_blank"
                className="pointer-cursor"
                rel="noreferrer"
              >
                <img src={DribbbleIcon} alt="dribbble" />
              </a>
              <a
                href={instagram}
                target="_blank"
                className="pointer-cursor"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
