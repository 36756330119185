export function onLoadAnims(timeline, tag = false, img = false) {
  timeline.from(
    '.navbar',
    {
      duration: 0.8,
      y: '-100%',
      opacity: 0,
      ease: 'power2.out',
    },
    '-=0.8'
  );
  timeline.fromTo(
    '.hero .main-text .inner',
    {
      y: '100%',
      rotationX: -90,
      opacity: 0,
    },
    {
      duration: 0.8,
      y: '0%',
      rotationX: 0,
      opacity: 1,
      ease: 'power2.out',
      stagger: 0.1,
    },
    '-=0.6'
  );
  if (tag) {
    timeline.from(
      '.hero #tag',
      {
        duration: 0.8,
        opacity: 0,
        ease: 'power2.out',
      },
      '-=0.4'
    );
  }

  if (img) {
    // timeline.from(
    //   '.hero-img',
    //   {
    //     duration: 0.8,
    //     y: '80%',
    //     opacity: 0,
    //     ease: 'power2.out',
    //   },
    //   '-=0.8'
    // );
    // timeline.from(
    //   '.hero-img img',
    //   {
    //     duration: 0.8,
    //     scale: 1.3,
    //     ease: 'power2.out',
    //   },
    //   '-=0.8'
    // );
    timeline.fromTo(
      '.hero-img .mask',
      { height: '100%' },
      {
        duration: 1,
        height: 0,
        ease: 'power2.out',
      },
      '-=0.8'
    );
    timeline.from(
      '.hero-img .inner > div',
      {
        duration: 1,
        scale: 1.3,
        ease: 'power2.out',
      },
      '-=1'
    );
  }
}
