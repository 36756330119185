import React, { useEffect, useRef } from 'react';

export default function Cursor({ location }) {
  const cursorEl = useRef();

  useEffect(() => {
    const touch = matchMedia('(hover: none), (pointer: coarse)').matches;

    if (touch) {
      cursorEl.current.style.opacity = 0;
    } else cursorEl.current.style.opacity = 1;

    if (!touch) {
      const cursor = cursorEl.current;
      const mouse = { x: -100, y: -100 };
      const pos = { x: 0, y: 0 };
      const speed = 0.15;

      const updateCoordinates = (e) => {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      };

      window.addEventListener('mousemove', updateCoordinates);

      const updatePosition = () => {
        pos.x += (mouse.x - pos.x) * speed;
        pos.y += (mouse.y - pos.y) * speed;
        cursor.style.transform = `translate3d(${pos.x}px ,${pos.y}px, 0)`;
      };

      /* eslint-disable */
      function loop() {
        updatePosition();
        requestAnimationFrame(loop);
      }
      /* eslint-enable */

      requestAnimationFrame(loop);

      // Case 1: change cursors
      const pointerCursor = document.querySelectorAll(
        '.pointer-cursor, .link, .button, button'
      );
      pointerCursor.forEach((element) => {
        element.addEventListener('mouseenter', () => {
          cursor.classList.add('pointer');
          if (cursor.classList.contains('swipe')) {
            cursor.classList.remove('swipe');
          }
        });
        element.addEventListener('mouseleave', () => {
          cursor.classList.remove('pointer');
          if (element.classList.contains('visit')) {
            cursor.classList.add('swipe');
          }
        });
        element.addEventListener('click', () => {
          cursor.classList.remove('pointer');
        });
      });

      // Case 1.1: change cursors for service tabs
      const pointerService = document.querySelectorAll('#services .service');
      pointerService.forEach((element) => {
        element.addEventListener('mouseenter', () => {
          if (element.classList.contains('pointer-cursor-2')) {
            cursor.classList.add('pointer');
          }
        });
        element.addEventListener('mouseleave', () => {
          cursor.classList.remove('pointer');
        });
        element.addEventListener('click', () => {
          cursor.classList.remove('pointer');
        });
      });

      // Case 2: swipe cursor
      const swipeCursor = document.querySelectorAll('.swipe-cursor');
      swipeCursor.forEach((element) => {
        element.addEventListener('mouseenter', () => {
          cursor.classList.add('swipe');
        });
        element.addEventListener('mouseleave', () => {
          cursor.classList.remove('swipe');
        });
      });
    }
  }, [location]);

  return (
    <>
      <div ref={cursorEl} className="cursor">
        <div className="cursor-inner" />
      </div>
    </>
  );
}
